import React, { Component, createRef } from 'react';
// import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
// import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
// import EConfig from '../../data/enumconfig';
// import './dashboard.scss';
// import moment from "moment";
// import 'jspdf-autotable'
// import { jsPDF } from "jspdf";

import Footer from '../../component/footer';
import Sidebar from '../../component/sidemenu';
import PageLoading from '../../component/loadinganimation/pageloading';

class dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            // fromDate: moment(new Date()).format("yyyy-MM-DD"),
            //  toDate: moment(new Date()).format("yyyy-MM-DD")
            menuOpen: false

        }
        this.mainRef = createRef();
        this.headerRef = createRef();
        this.footerRef = createRef();
        this.state = {
            paddingTop: 0,
            paddingBottom: 0
        };

    }

    toggleMenu = () => {
        this.setState(prevState => {
            const newMenuState = !prevState.menuOpen;

            if (newMenuState) {
                document.body.classList.add('menu-open');
                document.documentElement.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
                document.documentElement.classList.remove('menu-open');
            }

            return { menuOpen: newMenuState };
        });

        // Prevent default behavior if necessary
        return false;
    };

    closeSideMenu = () => {

        document.body.classList.remove('menu-open');
        document.documentElement.classList.remove('menu-open');
        this.setState({ menuOpen: false });
        // Prevent default behavior if necessary
        return false;
    };

    updateStyles = () => {
        const windowHeight = window.innerHeight;
        const headerHeight = this.headerRef.current ? this.headerRef.current.offsetHeight : 0;
        const footerHeight = this.footerRef.current ? this.footerRef.current.offsetHeight : 0;

        if (this.mainRef.current) {
            this.mainRef.current.style.minHeight = `${windowHeight}px`;
            this.setState({
                paddingTop: headerHeight + 10,
                paddingBottom: footerHeight + 10
            });
        }
    };

    componentDidMount() {
        this.updateStyles();
        window.addEventListener('resize', this.updateStyles);
        document.body.addEventListener('mousedown', this.closeSideMenu);

        // if (!sHelpers.defaultInputValidation()) {
        //     setTimeout(() => {
        //         this.props.history.push('/login');
        //     }, Config.minMsgTimeout);
        //     return false;
        // }

        if (!sHelpers.LoggedinInputValidation()) {
            this.props.alert.error("Session Expired! Please log in again.");
            this.setState({ loading: true });
            setTimeout(() => {
                this.props.history.push('/login');
            }, Config.minMsgTimeout);
            return false;
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateStyles);
        document.body.classList.remove('menu-open');
        document.documentElement.classList.remove('menu-open');
        document.body.removeEventListener('mousedown', this.closeSideMenu);
    };

    getGreeting = () => {
        const currentHour = new Date().getHours();

        if (currentHour >= 5 && currentHour < 12) {
            return 'Good Morning';
        } else if (currentHour >= 12 && currentHour < 17) {
            return 'Good Afternoon';
        } else if (currentHour >= 17 && currentHour < 21) {
            return 'Good Evening';
        } else {
            return 'Good Night';
        }
    };

    render() {
        const { paddingTop, paddingBottom } = this.state;
        return (
            <>
                <PageLoading />
                <Sidebar />
                {/* {this.state.loading && <LoadingAnimation />}
                <Header></Header> */}
                {/* <div className="section-space80 bg-gradient"> */}

                <main className="h-100"
                    ref={this.mainRef}
                    style={{
                        paddingTop: `${paddingTop}px`,
                        paddingBottom: `${paddingBottom}px`
                    }}
                // onClick={this.toggleMenu}
                >
                    <header ref={this.headerRef} className="header position-fixed">
                        <div className="row">
                            <div className="col-auto" onClick={this.toggleMenu.bind(this)}>
                                <span className="btn btn-light btn-44 menu-btn" >
                                    <i className="bi bi-list"></i>
                                </span>
                            </div>
                            <div className="col align-self-center text-center">
                                <div className="logo-small">
                                    {/* <img src="lucid-inventory.png" alt="" /> */}
                                    <h5>{sHelpers.secureStorage.getItem("inv.organization.name")}</h5>
                                </div>
                            </div>
                            {/* <div className="col-auto">
                                <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                                    <i className="bi bi-bell"></i>
                                    <span className="count-indicator"></span>
                                </a>
                            </div> */}
                        </div>
                    </header>

                    <div className="main-container container">
                        <div className="row mb-4">
                            <div className="col-auto">
                                <div className="avatar avatar-50 shadow rounded-10">
                                    <img src="user.png" alt="" />
                                </div>
                            </div>
                            <div className="col align-self-center ps-0">
                                <h4 className="text-color-theme"><span className="fw-normal">Hi</span>, {sHelpers.secureStorage.getItem("inv.user.name")}</h4>
                                <p className="text-muted">{this.getGreeting()}</p>
                            </div>
                        </div>


                        {/* <div className="row mb-3">
                            <div className="col">
                                <h6 className="title">Variation</h6>
                            </div>
                            <div className="col-auto">

                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-6 col-md-4 col-lg-3">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="circle-small">
                                                    <div id="circleprogressone"></div>
                                                    <div className="avatar avatar-30 alert-primary text-primary rounded-circle">
                                                        <i className="bi bi-file-text-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto align-self-center">
                                                <p className="small mb-1 text-muted">Total Variation</p>
                                                <p>60
                                                    <span className="small">records</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-3">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="circle-small">
                                                    <div id="circleprogresstwo"></div>
                                                    <div className="avatar avatar-30 alert-success text-success rounded-circle">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto align-self-center">
                                                <p className="small mb-1 text-muted">Variation Value</p>
                                                <p>2500.00
                                                    <span className="small">%</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <h6 className="title">Today<br />
                                    <small className="fw-normal text-muted"> 24/08/2024</small>
                                </h6>
                            </div>
                            <div className="col-auto align-self-center">
                                <a href="" className="small">View all</a>
                            </div>                            
                        </div>
                        <div className="row mb-5">
                            <div className="col-6 col-md-4 col-lg-3">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="circle-small">
                                                    <div id="circleprogressone"></div>
                                                    <div className="avatar avatar-30 alert-primary text-primary rounded-circle">
                                                        <i className="bi bi-file-text-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto align-self-center">
                                                <p className="small mb-1 text-muted">Total Variation</p>
                                                <p>10
                                                    <span className="small">records</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-3">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="circle-small">
                                                    <div id="circleprogresstwo"></div>
                                                    <div className="avatar avatar-30 alert-success text-success rounded-circle">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto align-self-center">
                                                <p className="small mb-1 text-muted">Variation Value</p>
                                                <p>500.00
                                                    <span className="small">%</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                    </div>
                </main>

                {/* <footer className="footer">
                    <div className="container">
                        <ul className="nav nav-pills nav-justified">
                            <li className="nav-item">
                                <a className="nav-link active" href="index.html">
                                    <span>
                                        <i className="nav-icon bi bi-grid"></i>
                                        <span className="nav-text">Dashboard</span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="stats.html">
                                    <span>
                                        <i className="nav-icon bi bi-file-text"></i>
                                        <span className="nav-text">Variation</span>
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="stats.html">
                                    <span>
                                        <i className="nav-icon bi bi-calendar"></i>
                                        <span className="nav-text">Report</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer> */}
                <Footer footerRef={this.footerRef} />


            </>
        )
    }
}
export default withAlert()(dashboard);