import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode, printSetting } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
import PageLoading from "../../../component/loadinganimation/pageloading";
import "./variation.scss";
import "./autocomplete.scss";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
let _yValue = printSetting.logoy;
class variationprint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			variationNo: 0,
			variationDate: "-",
			variationTime: "-",
			selectedItems: [],
			remarks: "-",
			enteredBy: "-",
			login: "-",
			btnDisabled: false,
			btnVisible: false,
			selectedItemValue: "",
			gst: "-",
			footer: '',
			city: '',
			state: '',
			pin: '',
			mobile: '',
			phone: '',
			email: '',
			address1: '',
			address2: '',
			logoUrl: ''
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		this.itemSearchRef = React.createRef();
		this.qtyRef = React.createRef();
		this.enteredByRef = React.createRef();
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);

		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		if (this.props.location.state) {
			this.setState({ variationNo: this.props.location.state.variationNo });
			setTimeout(() => {
				if (this.props.location.state.mode === entryMode.print) {
					this.getVariationPrint(this.props.location.state.variationNo);
				} else {
					this.props.history.push("/variation");
				}
			}, Config.minMsgTimeout);

			window.scrollTo(0, 0);
		} else {
			setTimeout(() => {
				this.props.history.push("/variation");
			}, Config.minMsgTimeout);
		}

		// handleMode
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
	};

	handleScrollIntoView(scrollRef) {
		window.scrollTo(0, 0);
		setTimeout(() => {
			scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, Config.averageMsgTimeout);
	};

	getVariationPrint = async (variationNo) => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(Config.baseAPIUrl + "store/variations/print/" + variationNo, {
				method: "GET",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((response) => {
					//console.log("getvariation", response);
					if (response.IsSuccessful) {
						// Store Detail values in the state for rendering
						//	 this.setState({ variationHeader: response.Header });
						this.setState({ selectedItems: response.Detail, btnVisible: true });
						this.setState({
							variationNo: response.Header.DisplayVariationNo,
							variationDate: response.Header.VariationDate,
							variationTime: response.Header.VariationTime,
							remarks: response.Header.Remarks,
							enteredBy: response.Header.EnteredBy,
							selectedPropertyName: response.Header.PropertyName,
							selectedPropertyCode: response.Header.PropertyCode,
							gst: response.Header.GST,
							selectedItems: response.Detail,
							login: response.Header.Login,
							state: response.Header.State,
							mobile: response.Header.Mobile,
							phone: response.Header.Phone,
							pin: response.Header.ZipCode,
							email: response.Header.Email,
							address1: response.Header.Address1,
							address2: response.Header.Address2,
							city: response.Header.City,
							logoUrl: response.Header.LogoUrl
						});
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	// handleExportToPdf() {
	//     this.setState({ loading: true });
	//     setTimeout(() => {
	//         let doc = new jsPDF('p', 'mm', 'a4');
	//         //let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
	//         let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

	//         doc.page = 1;
	//         doc.setFontSize(14);
	//         //let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(sHelpers.secureStorage.getItem('club.property.name')) * doc.internal.getFontSize() / 2);
	//         let str = this.state.selectedPropertyName;
	//         // doc.text(sHelpers.secureStorage.getItem('club.property.name'), xOffset, 10);
	//         doc.text(str, pageWidth / 2, 10, 'center');
	//         // doc.centerAlignText(sHelpers.secureStorage.getItem('club.property.name'), { align: "center" }, 0, 10);
	//         // doc.text(30, 10, sHelpers.secureStorage.getItem('club.property.name'));
	//         // doc.text(sHelpers.secureStorage.getItem('club.property.name'), 10, 10);
	//         doc.setFontSize(10);
	//         var footer = function (data) {
	//             doc.setFontSize(9);
	//             doc.text(8, 290, 'Page ' + doc.page);
	//             doc.text(130, 290, sHelpers.getCurrentDateTime("Printing Time: "));
	//             doc.page++;
	//         }

	//         // doc.text("OpeningBalance: " + this.state.opBalance, 80, 12);
	//         doc.autoTable({
	//             html: '#tbl-tranactionlist',
	//             startY: 14,
	//             columnStyles: {
	//                 1: {
	//                     halign: 'right'
	//                 },
	//                 2: {
	//                     halign: 'right'
	//                 }
	//             },
	//             headerStyles: {
	//                 lineWidth: 0,
	//                 //lineColor: [0, 0, 0],
	//                 fontStyle: 'bold',
	//                 fontSize: 10,
	//                 halign: 'center'

	//             },
	//             addPageContent: footer,
	//         });
	//         this.setState({ loading: false });
	//         doc.save("variation.pdf");
	//     }, Config.minMsgTimeout);

	// }

	// handleExportToPdf() {
	// 	this.setState({ loading: true });

	// 	setTimeout(() => {
	// 		let doc = new jsPDF("p", "mm", "a4");
	// 		let pageWidth =
	// 			doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
	// 		doc.page = 1;

	// 		// Header section
	// 		doc.setFontSize(16);
	// 		let propertyName = this.state.selectedPropertyName || "Property Name";
	// 		doc.text(propertyName, pageWidth / 2, 10, "center"); // Centered property name

	// 		doc.setFontSize(12);
	// 		doc.text("Variation", pageWidth / 2, 18, "center"); // Centered title

	// 		// Variation details
	// 		doc.setFontSize(10);
	// 		doc.text(`Variation Login: ${this.state.login}`, 15, 30);
	// 		doc.text(`GST No: ${this.state.gst || "N/A"}`, pageWidth - 60, 30); // Right-aligned

	// 		doc.text(`Variation No: ${this.state.variationNo}`, 15, 35);
	// 		doc.text(`Date: ${this.state.variationDate}`, pageWidth - 60, 35); // Right-aligned

	// 		doc.text(`Property: ${this.state.selectedPropertyName}`, 15, 40);
	// 		doc.text(`Entered By: ${this.state.enteredby}`, pageWidth - 60, 40); // Right-aligned

	// 		doc.text(`Remarks: ${this.state.remarks}`, 15, 45);

	// 		// Table headers
	// 		doc.autoTable({
	// 			head: [["Item Name", "Units", "Rate", "Stock Variation"]],
	// 			startY: 50,
	// 			body: this.state.selectedItems.map((item) => [
	// 				item.ItemName || "",
	// 				item.Units || "",
	// 				item.ItemRate.toFixed(2) || "",
	// 				item.VariationQty.toFixed(3) || "",
	// 			]),
	// 			columnStyles: {
	// 				0: { halign: "left" },
	// 				1: { halign: "center" },
	// 				2: { halign: "right" },
	// 				3: { halign: "right" },
	// 			},
	// 			styles: { fontSize: 10 },
	// 		});

	// 		// Footer section
	// 		let finalY = doc.lastAutoTable.finalY + 10;
	// 		doc.setFontSize(10);
	// 		doc.text(`Total Items: ${this.state.selectedItems.length}`, 15, finalY);

	// 		// Footer text with page number and printing time
	// 		var footer = (data) => {
	// 			doc.setFontSize(9);
	// 			doc.text(15, 290, "Page " + doc.page);
	// 			doc.text(130, 290, `Printing Time: ${new Date().toLocaleString()}`);

	// 			let userName = sHelpers.secureStorage.getItem("inv.user.name") || "N/A"; // Fetch from secure storage
	// 			doc.text(15, 295, `User: ${userName}`);

	// 			// Access `this.state.selectedPropertyName` correctly
	// 			doc.text(130, 295, "License To: " + this.state.selectedPropertyName);

	// 			doc.page++;
	// 		};

	// 		footer();

	// 		// Finalize PDF
	// 		this.setState({ loading: false });
	// 		doc.save("variation.pdf");
	// 	}, Config.minMsgTimeout);
	// }


	// handleExportToPdf() {
	// 	const lMargin = 15; //left margin in mm
	// 	const rMargin = 15; //right margin in mm
	// 	const pdfInMM = 210; // width of A4 in mm
	// 	let pdf = new jsPDF("p", "mm", "a4");
	// 	//let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login
	// 	let res = pdf.autoTableHtmlToJson();
	// 	let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super";

	// 	var _logoUrl = "";

	// 	pdf.setFont("courier");
	// 	pdf.setFontSize(16);

	// 	pdf.centerAlignText(
	// 		this.state.selectedPropertyName,
	// 		{ align: "center" },
	// 		0,
	// 		_yValue
	// 	);
	// 	pdf.setFontSize(12);
	// 	var addr1 = "address1";
	// 	var addr2 = "address2";
	// 	var city = "city";
	// 	var state = "";
	// 	var pin = "";
	// 	var mobile = "";
	// 	var phone = "";
	// 	var email = "";
	// 	if (addr1.trim() !== "" || addr2.trim() !== "") {
	// 		var fullAddress = addr1 + " " + addr2;
	// 		var lines = pdf.splitTextToSize(fullAddress, pdfInMM - lMargin - rMargin);
	// 		_yValue += 4; // Increment Y value for spacing
	// 		for (var i = 0; i < lines.length; i++) {
	// 			pdf.centerAlignText(lines[i], { align: "center" }, 0, _yValue);
	// 			_yValue += 4; // Increment Y value for each line of wrapped text
	// 		}
	// 		_yValue += -4; // Increment Y value for each line of wrapped text
	// 	}

	// 	if (city.trim() !== "" || state.trim() !== "" || pin.trim() !== "") {
	// 		var _printText =
	// 			(city.trim() !== "" ? city : "") +
	// 			(state.trim() !== "" ? (city !== "" ? ", " : "") + state : "") +
	// 			(pin.trim() !== "" ? " - " + pin : "");
	// 		var lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
	// 		_yValue += 4; // Increment Y value for spacing
	// 		for (var i = 0; i < lines.length; i++) {
	// 			pdf.centerAlignText(lines[i], { align: "center" }, 0, _yValue);
	// 			_yValue += 4; // Increment Y value for each line of wrapped text
	// 		}
	// 		_yValue += -4; // Increment Y value for each line of wrapped text
	// 	}
	// 	if (mobile.trim() !== "" || phone.trim() !== "") {
	// 		var _printText =
	// 			(phone.trim() !== "" ? "Phone: " + phone + "  " : "") +
	// 			(mobile.trim() !== "" ? "Mobile No: " + mobile : "");
	// 		var lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
	// 		_yValue += 4; // Increment Y value for spacing
	// 		for (var i = 0; i < lines.length; i++) {
	// 			pdf.centerAlignText(lines[i], { align: "center" }, 0, _yValue);
	// 			_yValue += 4; // Increment Y value for each line of wrapped text
	// 		}
	// 		_yValue += -4; // Increment Y value for each line of wrapped text
	// 	}

	// 	if (email.trim() != "") {
	// 		_yValue += 4;
	// 		pdf.centerAlignText(
	// 			"Email Id: " + email,
	// 			{ align: "center" },
	// 			0,
	// 			_yValue
	// 		); //29
	// 	}

	// 	_yValue += 9;
	// 	pdf.setFontSize(12);
	// 	pdf.setFontSize(16);
	// 	var imgData;
	// 	var _imageId = document.getElementById("imageId");
	// 	if (_logoUrl) {
	// 		_imageId.src = "data:image/png;base64," + _logoUrl + "";
	// 		imgData = document.getElementById("imageId");
	// 		pdf.addImage(
	// 			imgData,
	// 			printSetting.logox,
	// 			printSetting.logoy,
	// 			printSetting.logowidth,
	// 			printSetting.logowidth
	// 		);
	// 	}

	// 	pdf.centerAlignText("Variation", { align: "center" }, 0, 42);
	// 	pdf.setFontSize(10);
	// 	pdf.setTextColor(0, 2, 2);
	// 	pdf.text(10, 55, "Variation Login : " + Login);
	// 	//pdf.text(140,55,'GST No : ' + GetPropertyGST(parseInt($("#properties").attr('headers'))));
	// 	// Added by prem
	// 	var _gstNo = "";

	// 	if (!_gstNo) {
	// 		pdf.text(140, 55, "GST No : ");
	// 	} else {
	// 		pdf.text(140, 55, "GST No : " + _gstNo);
	// 	}

	// 	pdf.setDrawColor(0, 0, 0);
	// 	pdf.setLineWidth(0.1);
	// 	pdf.line(8, 60, 200, 60);

	// 	pdf.text(10, 65, "Variation No: " + (this.state.variationNo || "N/A"));
	// 	pdf.text(100, 65, "Date: " + (this.state.variationDate || "N/A"));
	// 	pdf.text(10, 70, "Property: " + (this.state.selectedPropertyName || "N/A"));
	// 	pdf.text(100, 70, "Entered By: " + (this.state.enteredby || "N/A"));
	// 	pdf.text(10, 75, "Remarks: " + (this.state.remarks || "N/A"));

	// 	// pdf.text(10, 65, "Variation No");
	// 	// pdf.text(50, 65, ": " + (this.state.variationNo) + "");
	// 	// pdf.text(100, 65, "Date");
	// 	// pdf.text(150, 65, ": " + $("#VarIDate").val() + "");

	// 	// pdf.text(10, 70, "Property");
	// 	// pdf.text(50, 70, ": " + $("#properties").val().substring(0, 20) + "");

	// 	// pdf.text(100, 70, "Entered By");
	// 	// pdf.text(150, 70, ": " + $("#enteredby").val().substring(0, 20) + "");

	// 	// pdf.text(10, 75, "Remarks");
	// 	// pdf.text(50, 75, ": " + $("#remarks").val().substring(0, 20) + "");

	// 	pdf.page = 1;
	// 	var footer = function (data) {
	// 		pdf.setFontSize(8);
	// 		pdf.text(8, 282, "Page " + pdf.page);
	// 		pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130
	// 		pdf.centerAlignText(
	// 			"User: " + Login,
	// 			{ align: "center" },
	// 			0,
	// 			286
	// 		);

	// 		pdf.centerAlignText(
	// 			"License To : " +
	// 				sHelpers.secureStorage.getItem("inv.organization.name"),
	// 			{ align: "center" },
	// 			0,
	// 			290
	// 		);
	// 		pdf.page++;
	// 	};

	// 	var options = {
	// 		addPageContent: footer,
	// 		margin: {
	// 			left: 8,
	// 		},
	// 		theme: "plain",
	// 		headerStyles: {
	// 			lineWidth: 0.1,
	// 			lineColor: [0, 0, 0],
	// 			fontStyle: "bold",
	// 			fontSize: 10,
	// 			halign: "center",
	// 		},
	// 		tableWidth: "100%",
	// 		styles: {
	// 			cellPadding: 0.6,
	// 			overflow: "linebreak",
	// 			font: "courier",
	// 			lineColor: [0, 0, 0],
	// 			lineWidth: 0.1,
	// 			fontSize: 11,
	// 		},
	// 		columnStyles: {
	// 			0: { columnWidth: 110 },
	// 			1: { columnWidth: 23, halign: "left" },
	// 			2: { columnWidth: 23, halign: "right" },
	// 			3: { columnWidth: 35, halign: "right" },
	// 		},

	// 		startY: 80,
	// 	};

	// 	pdf.autoTable(res.columns, res.data, options);

	// 	var sstartY = pdf.autoTableEndPosY();
	// 	pdf.setDrawColor(0, 0, 0);
	// 	pdf.setLineWidth(0.1);
	// 	pdf.line(8, sstartY + 5, 200, sstartY + 5);

	// 	pdf.text(140, sstartY + 10, "Total Items :");
	// 	pdf.text(180, sstartY + 10, "" + 10 + "");

	// 	pdf.setLineWidth(0.1);
	// 	pdf.line(8, sstartY + 14, 200, sstartY + 14);

	// 	_yValue = sstartY + 18;
	// 	this.FindandAddNewPage(pdf, 30);

	// 	pdf.setFontSize(10);
	// 	pdf.text(10, sstartY + 30, "Prepared By");
	// 	pdf.text(150, sstartY + 30, "Store Keeper");

	// 	pdf.save("Variation.pdf");
	// }

	handleExportToPdf = () => {

		const lMargin = 15; // Left margin in mm
		const rMargin = 15; // Right margin in mm
		const pdfInMM = 210; // Width of A4 in mm
		let pdf = new jsPDF("p", "mm", "a4"); // Initialize jsPDF instance
		_yValue = printSetting.logoy;

		if (this.state.logoUrl) {
			const _imgData = "data:image/png;base64," + this.state.logoUrl + "";
			//pdf.addImage(_imgData, printSetting.logox, printSetting.logoy, printSetting.logowidth, printSetting.logoheight);
			pdf.addImage(_imgData, 'JPEG', printSetting.logox, printSetting.logoy, printSetting.logowidth, printSetting.logoheight);
		}

		// Set font and font size
		pdf.setFont("courier");
		pdf.setFontSize(16);

		_yValue += 20;

		// Custom function to center text
		const centerText = (text, y) => {
			let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
			let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
			pdf.text(textOffset, y, text);
		};

		// Add header information (Property Name)
		const propertyName = this.state.selectedPropertyName || "Property Name";
		centerText(propertyName, _yValue);
		//_yValue = _yValue; // Initialize Y value for spacing after header

		// Reduce font size for further details
		pdf.setFontSize(12);

		// Update address and other details from state or default values
		let addr1 = String(this.state.address1 || "");
		let addr2 = String(this.state.address2 || "");
		let city = String(this.state.city || "");
		let state = String(this.state.state || "");
		let pin = String(this.state.pin || "");
		let mobile = String(this.state.mobile || "");
		let phone = String(this.state.phone || "");
		let email = String(this.state.email || "");
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login


		// Add address information (Address Line 1 and Address Line 2)
		if (addr1.trim() !== "" || addr2.trim() !== "") {
			let fullAddress = addr1 + " " + addr2;
			let lines = pdf.splitTextToSize(fullAddress, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text

			}
			_yValue += -4; // Decrement Y value for each line of wrapped text
		}

		// Add city, state, and pin information
		if (city.trim() !== "" || state.trim() !== "" || pin.trim() !== "") {
			let _printText =
				(city.trim() !== "" ? city : "") +
				(state.trim() !== "" ? (city !== "" ? ", " : "") + state : "") +
				(pin.trim() !== "" ? " - " + pin : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after city/state/pin
		}

		// Add phone and mobile information
		if (mobile.trim() !== "" || phone.trim() !== "") {
			let _printText =
				(phone.trim() !== "" ? "Phone: " + phone + "  " : "") +
				(mobile.trim() !== "" ? "Mobile No: " + mobile : "");
			let lines = pdf.splitTextToSize(_printText, pdfInMM - lMargin - rMargin);
			_yValue += 4; // Increment Y value for spacing
			for (let i = 0; i < lines.length; i++) {
				centerText(lines[i], _yValue);
				_yValue += 4; // Increment Y value for each line of wrapped text
			}
			_yValue += -4; // Add additional spacing after phone/mobile
		}

		// Add email information
		if (email.trim() !== "") {
			_yValue += 4; // Increment Y value for spacing
			centerText("Email Id: " + email, _yValue); // Email information			
		}

		// Prepare for the next section (e.g., logo or main content)
		_yValue += 9; // Space for the next section

		// Add logo if available
		var imgData;
		var _logoUrl = ""; // Replace with the actual logo URL
		if (_logoUrl) {
			let _imageId = document.getElementById("imageId");
			_imageId.src = "data:image/png;base64," + _logoUrl; // Set image source
			imgData = document.getElementById("imageId"); // Get image element
			pdf.addImage(imgData, "PNG", 15, _yValue, 40, 20); // Adjust the image position and size
			_yValue += 30; // Adjust Y value based on the image height
		}

		pdf.setFontSize(16);
		// Add the main section title (e.g., Variation)
		centerText("Variation", _yValue);
		_yValue += 8; // Add spacing after the title
		pdf.setFontSize(10);
		pdf.setTextColor(0, 2, 2);

		// Add variation details (login and other info)
		pdf.text(10, _yValue, "Variation Login : " + Login); // Add Login information
		const gstData = this.state.gst;
		var _gstNo = gstData;
		pdf.text(140, _yValue, _gstNo ? "GST No : " + _gstNo : "GST No : "); // GST information

		// Line separator for neatness
		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		pdf.line(8, _yValue + 5, 200, _yValue + 5); // Draw a line

		// Add other variation information (e.g., variation number, date, etc.)
		_yValue += 10; // Increment Y value for the next section
		pdf.text(10, _yValue, "Variation No : " + (this.state.variationNo || ""));
		pdf.text(100, _yValue, "Date       : " + (this.state.variationDate || ""));
		pdf.text(10, _yValue + 5, "Property     : " + (this.state.selectedPropertyName || ""));
		pdf.text(100, _yValue + 5, "Entered By : " + (this.state.enteredBy || ""));
		pdf.text(10, _yValue + 10, "Remarks      : " + (this.state.remarks || ""));

		pdf.page = 1;
		var footer = (data) => {
			pdf.setFontSize(8);
			pdf.text(8, 282, 'Page ' + pdf.page);
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130
			centerText("User: " + sHelpers.secureStorage.getItem("inv.user.name"), 286);
			//pdf.centerAlignText('User: ' + sHelpers.secureStorage.getItem("inv.user.name"), { align: "center" }, 0, 286);
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);
			pdf.page++;
		}



		const options = {

			addPageContent: footer,
			margin: {
				left: 8
			},

			theme: 'plain',
			headerStyles: {

				lineWidth: 0.1,
				lineColor: [0, 0, 0],
				fontStyle: 'bold',
				fontSize: 10,
				halign: 'center'

			},
			tableWidth: '100%',
			styles: {
				cellPadding: 0.6,
				overflow: 'linebreak',
				font: 'courier',
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				fontSize: 11
			},
			columnStyles: {
				0: { columnWidth: 110 },
				1: { columnWidth: 23, halign: 'left' },
				2: { columnWidth: 23, halign: 'right' },
				3: { columnWidth: 35, halign: 'right' }
			},

			startY: _yValue + 15

		};

		const tableData = this.state.selectedItems.map((item) => [
			item.ItemName,
			item.Units,
			item.ItemRate,
			item.VariationQty,
		]);

		// Define columns
		const columns = [
			{ header: 'Item Name', dataKey: 'itemName' },
			{ header: 'Units', dataKey: 'units' },
			{ header: 'Item Rate', dataKey: 'itemRate' },
			{ header: 'Variation Qty', dataKey: 'variationQty' },
		];

		// Convert tableData into an object format suitable for autoTable
		const tableObjectData = tableData.map((row, index) => ({
			itemName: row[0],
			units: row[1],
			itemRate: row[2],
			variationQty: row[3],
		}));


		//pdf.autoTable(tableColumns, tableData, options);
		//const jsonGrid = this.state.selectedItems
		pdf.autoTable(columns, tableObjectData, options);
		var _startY = pdf.autoTableEndPosY();
		pdf.setDrawColor(0, 0, 0);
		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 5, 200, _startY + 5);

		pdf.text(140, _startY + 10, "Total Items :");
		pdf.text(180, _startY + 10, "" + tableData.length + "");
		pdf.setLineWidth(0.1);
		pdf.line(8, _startY + 14, 200, _startY + 14);
		_yValue = _startY + 18;
		this.FindandAddNewPage(pdf, 30);
		pdf.setFontSize(10);
		pdf.text(10, _startY + 30, 'Prepared By');
		pdf.text(150, _startY + 30, 'Store Keeper');
		// Save the PDF
		pdf.save("Variation.pdf");
	};

	FindandAddNewPage(pdf, addValue) {
		let Login = sHelpers.secureStorage.getItem("inv.user.name") || "super"; // User Login
		_yValue += addValue;

		if (_yValue > 268) {
			pdf.addPage();
			pdf.setFontSize(8);
			pdf.text(8, 282, "Page " + pdf.internal.getNumberOfPages()); // Use internal method for page numbers
			pdf.text(130, 282, this.GetCurrentDateTime("Printing Time: ")); //130

			// Custom implementation to center text
			const centerText = (text, y) => {
				let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
				let textOffset = (pdf.internal.pageSize.width - textWidth) / 2;
				pdf.text(textOffset, y, text);
			};

			// Center-align the text "User: Login"
			centerText("User: " + Login, 286);

			// Center-align the text "License To: Login"
			centerText("License To : " + sHelpers.secureStorage.getItem("inv.lic.name"), 290);

			_yValue = 10; // Reset Y value for new page
		}
	};

	GetCurrentDateTime(Inptxt) {
		var currentdate = new Date();
		var hours = currentdate.getHours();
		var ampm = hours >= 12 ? "PM" : "AM";

		var datetime =
			Inptxt +
			currentdate.getDate() +
			"/" +
			(currentdate.getMonth() + 1) +
			"/" +
			currentdate.getFullYear() +
			" " +
			currentdate.getHours() +
			":" +
			currentdate.getMinutes() +
			":" +
			currentdate.getSeconds() +
			" " +
			ampm;

		return datetime;
	};


	imageToBase64(img) {
		var canvas, ctx, dataURL;
		canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);
		dataURL = canvas.toDataURL("image/(png|jpg|jpeg");
		return dataURL;
	};


	goBack() {
		this.props.history.push("/variation");
	}

	render() {
		const { paddingTop, paddingBottom } = this.state;

		return (
			<>
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5>Variation</h5>
							</div>
							{/* <div className="col-auto">
                                <a href="notifications.html" target="_self" className="btn btn-light btn-44">
                                    <i className="bi bi-bell"></i>
                                    <span className="count-indicator"></span>
                                </a>
                            </div> */}
						</div>
					</header>

					<div className="main-container container">
						<div className="card mb-4">
							<div className="card-body">
								<div className="row">
									<div className="col-auto">
										<div className="avatar avatar-60 shadow rounded-10 ">
											<i className="nav-iconbi bi-file-text fw-bold"></i>
										</div>
									</div>
									<div className="col align-self-center ps-0">
										<h5 className="text-color-theme mb-0">
											{this.state.variationNo}
										</h5>
										<p className="text-muted">Variation No</p>
									</div>
									<div className="col-auto  align-self-center text-end">
										<h6 className="mb-0">{this.state.variationDate}</h6>
										<p className="text-muted">{this.state.variationTime}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Header</h6>
							</div>
							<div className="col-auto"></div>
						</div>

						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Property Name</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">
									{this.state.selectedPropertyName}
								</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Remarks</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.remarks}</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Entered By</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.enteredBy}</p>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col">
								<p className="text-muted">Variation Login</p>
							</div>
							<div className="col text-left">
								<p className="text-color-theme">{this.state.login}</p>
							</div>
						</div>

						<br />
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">
									Detail
									<br />
								</h6>
							</div>
							<div className="col-auto align-self-center">
								<small className="fw-normal text-muted">
									Total Items:{" "}
									<span className="text-color-theme size-16 fw-bold">
										{this.state.selectedItems.length}
									</span>
								</small>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12 px-0">
								<ul className="list-group list-group-flush scroll-height-300">
									{this.state.selectedItems.map((row) => (
										<li className="list-group-item">
											<div className="row">
												<div className="col-auto align-self-center text-left">
													<p className="text-color-theme mb-0">{row.ItemName}</p>
													<p className="text-muted size-12">{row.StoreName}</p>
												</div>
												<div className="col align-self-center text-left">
													<p className="text-color-theme mb-0">{row.Units}</p>
													<p className="text-muted size-12">Units</p>
												</div>
												<div className="col align-self-center text-left">
													<p className="text-color-theme mb-0">{row.Rate}</p>
													<p className="text-muted size-12">Rate</p>
												</div>
												<div className="col align-self-center text-left">
													<p className="text-color-theme mb-0">
														{row.VariationQty}
													</p>
													<p className="text-muted size-12">Stock Variation</p>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className="row">
							<div className="col">
								{this.state.selectedItems && (
									<button
										className="btn btn-light btn-lg shadow-sm w-100"
										onClick={this.handleExportToPdf.bind(this)}
										style={{ display: this.state.btnVisible ? "block" : "none" }}
									>
										<i className="bi bi-file-earmark-pdf me-2"></i>Export
									</button>
								)}
							</div>
						</div>
						<image id="img-print-logo" src="" className="hidden"></image>
						<br />
					</div>
				</main>

			</>
		);
	}
}
export default withAlert()(variationprint);
