const Config = {
    siteTitle: "Lucid Inventory App", // Site title.
    siteTitleShort: "Lucid Inventory App", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Lucid Inventory App", // Alternative site title for SEO.
    siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
    siteUrl: "http://localhost:2018", // Domain of your website without pathPrefix.
    pathPrefix: "/lco",
    siteImagePathUrl: "./t1/assets/images/lucid-logo.png",

    //local
    // baseAPIUrl: "http://localhost:3020/api/v1/inventoryservices/",
    // invAPIUserName: "lucid@",
    // invAPIPassword: "lucid@#$",

    //dev
    // baseAPIUrl: "https://link.lucidits.com/Inventorytest/api/v1/inventoryservices/",
    // // // baseAPIUrl: "http://192.168.5.103/release/inventory/api/v1/inventoryservices/",
    // invAPIUserName: "lucid@dev",
    // invAPIPassword: "lucid@201@$",

    //live
    baseAPIUrl: "https://inventory.lucidpos.com/windmills/api/v1/inventoryservices/",
    invAPIUserName: "lucid@windmills",
    invAPIPassword: "windmills@@)@$",

    razorpayRedirectUrl: "https://api.razorpay.com/v1/checkout/embedded",
    urlAliveDurationMinutes: 15, // 15 Minitues    
    applicationCode: "INV-APP",

    secretkey: '$LuC!DINV@)!^$',
    minMsgTimeout: 800,
    maxMsgTimeout: 1500,
    averageMsgTimeout: 1200,
    verificationTimeout: 4000,
    bannerImageType: {
        Normal: 1,
        Logo: 2,
        Slider: 3,
        BackGroundImage: 4
    },
    pageIds: {
        home: 1,
        menu: 2
    },
    pageType: {
        normal: 1,
        home: 2,
        contact: 3,
        gallery: 4,
        listView: 5,
        member: 6
    },
    pageSession: {
        Home: 1
    },
    paymentVendor: {
        hdfcCCAvenue: 1,
        razorPay: 2
    },
    lazyLoading: {
        NoOfRecords: 10
    },
    entryMode: {
        new: 'New',
        edit: 'Edit',
        view: 'View',
        delete: 'Delete',
        print: 'Print'
    },
    printSetting: {
        logox: 83,
        logoy: 5,
        logowidth: 40,
        logoheight: 10,
    },
};

module.exports = Config;